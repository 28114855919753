<template>
  <div class="regscreen">
    <h1>Выбор региона</h1>
    <div class="regionbox">
      <div class="scroll">
        <div v-for="reg in regions"
          :id="reg.id"
          class="reg none-selectable"
          v-bind:class="{'active': reg.id===cur_reg}"
          v-on:click="selectreg(reg.id)">
          <p class="regname">{{ reg.name }}</p>
          <p class="regdescr">{{ reg.descr }}</p>
        </div>
      </div>
    </div>
    <router-link
      to="/nosology"
      class="next_btn">
      Продолжить
    </router-link>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import dataStore from '@/views/data';

export default {
  components:{
  },
  data:function(){
    return {
      cur_reg:1,
      regions:[
      ]
    }
  },
  methods:{
    selectreg:function(id){
      this.cur_reg = id;
      dataStore.currRegion = id;
      dataStore.currMult = dataStore.selectedRegs[id-1].inter;
      dataStore.currLawDoc = dataStore.selectedRegs[id-1].lawdoc;
      eventBus.$emit('region-change', id);
    }
  },
  mounted(){
    let regData = {}, newKey, regObj;

    dataStore.initRegData();
    regData = dataStore.selectedRegs;
    const keys = Object.keys(regData);
    keys.forEach((key, index) => {
      newKey = parseInt(key) + 1;
      regObj = {};
      regObj.id = newKey;
      regObj.name = regData[key].regName;
      regObj.descr = regData[key].regDescr;
      regObj.inter = regData[key].regDescr;      
      this.regions.push(regObj);
    });
    eventBus.$emit('region-change', dataStore.currRegion);
    dataStore.currLawDoc = dataStore.selectedRegs[dataStore.currRegion-1].lawdoc;
    dataStore.currMult = dataStore.selectedRegs[dataStore.currRegion-1].inter;
  }  
}
</script>
<style scoped>
h1{
  margin-top:30px;
}
.regscreen{
  padding-bottom: 10px;
}
.regionbox{
  display:flex;
  flex-flow: wrap;
  padding: 40px;
  background: linear-gradient(90deg, rgb(255,255,255,0.4), transparent);
  margin: 25px 0 0 3px;
  height:400px;
}
.scroll{
  overflow-y:scroll;
  width:100%;
  display:block;
  height:inherit;
}
.scroll::-webkit-scrollbar {
  width: 13px;               /* width of the entire scrollbar */
}

.scroll::-webkit-scrollbar-track {
  background: #aacedc;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #c3dce5;
  border-radius: 20px;
  border: 1px solid #cadfe6;
}
.reg{
  width:100%;
  padding: 5px 25px 0 0;
  box-sizing: border-box;
  color:#787572;
  cursor: pointer;
}
.reg.active{
  color:#F26321;
}
.regname{
  margin: 4px 0px;
  font-weight:600;
}
.regdescr{
  margin: 2px 0 10px 0;
  font-size: 12px;
}
.next_btn{
  margin: auto;
  margin-top: 15px;
  display: block;
  width: 200px;
  background-color: #03417A;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  text-align: center;
  padding: 12px;
}
</style>